import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import LogoImg from "../../../static/i/logo-center-licenziy.png"
import OGImg from "../../../static/i/logo-center-licenziy.png"
import FonteUkraineBody from "../../fonts/e-Ukraine-Regular.woff2"
import FonteUkraineHead from "../../fonts/e-UkraineHead-Regular.woff2"

const SEO = ({ title, description, image, url, lang, noindex }) => {
  const link = "https://лицензии.укр"
  // const language = url.substring(1, 3) === "ru" ? "ru" : "uk"
  const coreUrl = url.substring(1, 3) === "ru" ? url.substring(3) : url
  // const url = lang === "ru" ? `/ru${url}` : url
  // url === "/eori/"
  return (
    <Helmet>
      <html amp lang={lang} />
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <link rel="canonical" href={`${link}${url}`} />

      {noindex === "noindex" ? <meta name="robots" content="noindex" /> : ``}

      {/* Lang */}
      <link rel="alternate" href={`${link}${coreUrl}`} hreflang="x-default" />
      <link rel="alternate" href={`${link}${coreUrl}`} hreflang="uk" />
      <link rel="alternate" href={`${link}/ru${coreUrl}`} hreflang="ru" />

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${link}${url}`} />
      <meta property="og:locale" content={lang} />
      <meta
        property="og:locale:alternate"
        content={lang === "ru" ? "uk" : "ru"}
      />
      <meta
        property="og:site_name"
        content={lang === "ru" ? "Центр Лицензий" : "Центр Ліцензій"}
      />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="620" />
      <meta property="og:image:height" content="460" />
      <meta property="fb:app_id" content="2248427288812118" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@licenzii" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      {/* https://github.com/nfl/react-helmet */}

      {/* logo scheme
      Пример работающей разметки у конкурента https://nautilus-work.com/Usefull/poleznye-stati/kak-poluchit-chip-kartu-dlya-tahografa
      <script type="application/ld+json">
        {
        "@context": "http://schema.org/",
        "@type": "Product",
        "name": "Чип Карта Водителя Международника Киев. Как �? Где Получить Чип-Карту Для Тахографа?",
        "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue" : "4.9",
        "ratingCount": "198",
        "reviewCount": "58"
        }
        }
      </script> 
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "${link}",
          "logo": "${link + LogoImg}"
        }
      `}</script> */}
      {/* org scheme */}
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "LegalService",
          "@id": "${link}",
          "name": "Центр Ліцензій",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Pidlisna St, 1, 8",
            "addressLocality": "Kyiv",
            "addressRegion": "Kyiv",
            "postalCode": "03164",
            "addressCountry": "UK"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 50.4720894,
            "longitude": 30.3374664
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.8",
            "bestRating": "5",
            "ratingCount": "46"
          },
          "url": "https://лицензии.укр/kontakty/",
          "telephone": "+380683328808",
          "priceRange": "$",
          "logo": "${link + LogoImg}",
          "image": [
            "${link + OGImg}"
          ],
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "10:00",
              "closes": "18:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "10:00",
              "closes": "16:00"
            }
          ]
        }
      `}</script>

      <link
        rel="preload"
        as="font"
        href={FonteUkraineHead}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FonteUkraineBody}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  url: null,
  lang: "uk",
}

export default SEO
